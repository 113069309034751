import Vue from "vue";
const vm = Vue.prototype;
import CryptoJS from "gurhan/helpers/Crypto";
import router from "../../router";
import i18n from "../../plugins/i18n";
const crypto = new CryptoJS("MAKETODAYAMAZING");
import ModulesJson from "../../enums/modules.json";

// site için kullanılacak base _ content data modeli ve filtrelenebilir.
const GET_BASE_CONTENT = (filter = "") => {
  const content = {
    comments: [],
    sponsorList: [],
    tr: {
      seo: {
        home: {
          title: "",
          description: "",
          image: "",
          url: "",
        },
      },
      home: {
        banner: {
          title: "",
          description: "",
          overview: "",
        },
      },
      about1: {
        title: "",
        description: "",
        name: "",
        overview: "",
        overview2: "",
        features1: "",
        features2: "",
        features3: "",
        diplomasandCertificates: "Diploma & Sertifikalar",
        diplomas: "",
      },
    },
    en: {
      seo: {
        home: {
          title: "",
          description: "",
          image: "",
          url: "",
        },
      },
      home: {
        banner: {
          title: "",
          description: "",
          overview: "",
        },
      },
      about1: {
        title: "",
        description: "",
        name: "",
        overview: "",
        overview2: "",
        features1: "",
        features2: "",
        features3: "",
        diplomasandCertificates: "Diploma & Sertifikalar",
        diplomas: "",
      },
    },
    de: {
      seo: {
        home: {
          title: "",
          description: "",
          image: "",
          url: "",
        },
      },
      home: {
        banner: {
          title: "",
          description: "",
          overview: "",
        },
      },
      about1: {
        title: "",
        description: "",
        name: "",
        overview: "",
        overview2: "",
        features1: "",
        features2: "",
        features3: "",
        diplomasandCertificates: "Diploma & Sertifikalar",
        diplomas: "",
      },
    },
  };
  if (filter !== "") {
    return filter.split(".").reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, content || self);
  }
  return content;
};
const settings = {
  id: -1,
  isCourses: 1,
  isEvents: 1,
  isBooks: 1,
  isSeances: 1,
  isBlog: 1,
  isFile: 1,
  departmentId: 0,
  remoteSubscriptionId: null,
  title: "Gökçe Özel",
  phone: "+90 534 209 69 35",
  location: "Güzelbahçe",
  email: "info@gokceozel.com.tr",
  creditCardConfirmDate: null,
  twitter: "https://twitter.com/profdrgokceozel",
  facebook: "https://www.facebook.com/profdrgokceozelklinik",
  instagram: "https://www.instagram.com/profdrgokceozelklinik/",
  linkedin: "https://www.linkedin.com/in/gokce-ozel-m-d-18666417/",
  youtube: "https://www.youtube.com/channel/UCPjdRlsO9Fu0p5ZxQm-ZMTA",
  bannerImg: "/assets/imgs/banner-img.png",
  aboutImg: "/assets/imgs/about/01.png",
  logo: "/assets/images/logo-addworks.png",
  noImage: "/assets/images/logo-addworks.png",
  themeColor: "#FF561C",
  bannerColor: "#fff",
  primaryColor: "#26435b",
  tags: "",
  studentCount: 15,
  productCount: "3+",
  satisfactionAvg: 90,
  buyerCount: "3K+",
  differentCountryCount: "5",
  contents: GET_BASE_CONTENT(),
  comments: [],
};

// const BASE_URL = "http://192.168.1.98:5004";
const BASE_URL = "https://api.heocademy.com";

const GET_COMPANY_SETTINGS = () => {
  console.log("here=");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  let data = location.origin.split("https://")[1];
  let url = `${BASE_URL}/Companies/CompanySettings?website=${data}`;

  if (data)
    if ((data.match(/\./g) || []).length === 1) {
      data = "www." + data;
      url = `${BASE_URL}/Companies/CompanySettings?website=${data}`;
    }
  if (location.origin.includes("panel"))
    url = `${BASE_URL}/Companies/PanelSettings?panelWebsite=${location.origin}`;
  if (
    location.origin.includes("192.168") ||
    location.origin.includes("localhost")
  )
    url = `${BASE_URL}/Companies/PanelSettings?panelWebsite=https://panel.zirvekisiselgelisim.com.tr`;
  console.log(url, "urll");
  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.message === "OK") {
        const resultData = result.data;
        var requestOptionsToken = {
          method: "POST",
          redirect: "follow",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyId: resultData.info.id,
          }),
        };
        console.log(requestOptionsToken);
        return fetch(
          `${BASE_URL}/Admins/ExternalCompanyLogin`,
          requestOptionsToken
        )
          .then((responseToken) => responseToken.json())
          .then((resultToken) => {
            return {
              ...result,
              tokenResponse:
                resultToken.message === "OK" ? resultToken.data : "",
            };
          })
          .catch(() => {
            return {
              message: "fail",
            };
          });
      }
    })
    .catch((error) => console.log("error", error));
};

export default {
  state: {
    pageIsClosed: false,
    activeUser: {}, // active user's info
    loginIsLoading: false, // login button loading
    token: "", // user's token;
    alertBox: {
      // alert box in login page
      status: false,
      message: "",
      variant: "",
    },
    settings,
    settingsLoaded: false,
    heocademyToken: "",
    fileSizeOfCompany: {},
    activePlan: { loading: true, status: false, modules: [] },
    loadingOfFileSizeOfCompany: true,
  },
  getters: {
    getSettings: (state) => state.settings,
    getActivePlan: (state) => state.activePlan,
    getPageIsClosed: (state) => state.pageIsClosed,
    getFileSize: (state) => state.fileSizeOfCompany,
    getLoadingOfFileSize: (state) => state.loadingOfFileSizeOfCompany,
  },
  mutations: {
    SET_POLICY_DATE(state, payload) {
      state.settings.creditCardConfirmDate = payload;
    },
    SET_ACTIVE_PLAN(state, payload) {
      state.activePlan.loading = true;
      if (payload.message === "OK") {
        const planList = payload.data;
        if (planList.length > 0) {
          const plan = { status: true, ...planList[0] };
          console.log("plan, ", plan);
          state.activePlan = {
            status: true,
            ...plan,
            modules: ModulesJson.map((mj) => ({
              ...mj,
              isActive: plan.package.modules.find((m) => m.id === mj.id)
                ? true
                : false,
              value: mj.value || mj.price,
            })),
          };
        } else {
          state.activePlan = {
            status: false,
            modules: [],
          };
        }
      }
      state.activePlan.loading = false;
    },
    SET_HEOCADEMY_TOKEN(state, payload) {
      vm.$apiHeocademy.token = payload;
      state.heocademyToken = payload;
    },
    // for logout user.
    SET_LOGOUT(state, { variant = "sad", message = "" }) {
      state.activeUser = {};
      state.token = {};
      localStorage.removeItem("hm_tkn");
      localStorage.removeItem("hm_expired_at");
      localStorage.removeItem("hm_user");
      localStorage.removeItem("rez_info");
      state.alertBox = {
        status: true,
        message: i18n.t(message),
        variant: variant,
      };
      router.push({ name: "SignIn" });
      if (message === "sessionDestroyed") location.reload();
    },
    // for when login is loading
    SET_LOGIN_IS_LOADING(state, payload) {
      state.loginIsLoading = payload;
    },
    // for show alert to user
    SET_ALERT_BOX(state, payload) {
      state.alertBox = payload;
    },
    // for set active user
    SET_ACTIVE_USER(state, payload) {
      vm.$api.token = payload.token;
      state.token = payload.token;
      state.activeUser = payload;
      localStorage.setItem("hm_tkn", crypto.crypto(state.token));
      localStorage.setItem("hm_user", crypto.crypto(JSON.stringify(payload)));
      if (payload.init) {
        var date = new Date();
        date.setDate(date.getDate() + 6); // 7 gün token süresi 6 gün açık kalma süresi
        localStorage.setItem(
          "hm_expired_at",
          crypto.crypto(String(date.getTime()))
        );
      }
    },
    SETTINGS_IS_LOADED(state, payload) {
      console.log("loaded", payload.settings);
      state.settings = payload.settings;
      state.settingsLoaded = payload.status;
    },
    SET_FILE_SIZE_OF_COMPANY(state, payload) {
      state.fileSizeOfCompany = payload;
    },
    SET_LOADING_OF_FILE_SIZE_OF_COMPANY(state, payload) {
      state.loadingOfFileSizeOfCompany = payload;
    },
  },
  actions: {
    // the function will work project is started
    // for is there user information in app
    INIT_APP({ commit }) {
      try {
        const today = new Date().getTime();
        const eAt = crypto.decrypto(localStorage.getItem("hm_expired_at"));
        const tkn = crypto.decrypto(localStorage.getItem("hm_tkn"));
        const user = JSON.parse(
          crypto.decrypto(localStorage.getItem("hm_user"))
        );
        if (eAt && tkn && user && today < Number(eAt)) {
          // 16 aralık ve 15 aralık
          if (!Object.keys(user).includes("whatsappIsActive"))
            return commit("SET_LOGOUT", {
              variant: "danger",
              message: "sessionTimeout",
            });
          commit("SET_ACTIVE_USER", { ...user, init: false });
          //dispatch("GET_FILE_SIZE_OF_COMPANY"); // kategori listesi çekilir.
        } else
          commit("SET_LOGOUT", {
            variant: "danger",
            message: "sessionTimeout",
          });
      } catch (error) {
        commit("SET_LOGOUT", { variant: "danger", message: "sessionTimeout" });
      }
    },
    // for the update user, with set on project.
    async SEND_TO_UPDATE_USER({ commit }, user) {
      const fd = new FormData();
      if (user.newImage !== null) {
        fd.append("Image", user.newImage);
      }
      fd.append("IsActive", 1);
      fd.append("Email", user.email);
      fd.append("Fullname", user.fullname);
      fd.append("Phone", user.phone);
      const response = await vm.$api.put(`Users/${user.id}`, fd);
      if (response.message === "OK") {
        commit("SET_ACTIVE_USER", { ...user, init: false });
        return {
          status: true,
          variant: "success",
          message: "validMessage.updated",
        };
      } else if (response.message === "REGISTERED")
        return {
          status: true,
          variant: "danger",
          message: "validMessage.registeredUserInUpdate",
        };
      else
        return {
          status: true,
          variant: "danger",
          message: "validMessage.error",
        };
    },
    // login actions
    async SEND_TO_LOGIN({ commit, dispatch }, payload) {
      commit("SET_ALERT_BOX", { status: false });
      commit("SET_LOGIN_IS_LOADING", true);
      const response = await vm.$api.login(payload);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_ACTIVE_USER", { ...response.data, init: true });
        commit("SET_ALERT_BOX", {
          status: true,
          message: "Giriş işlemi başarılı. Ana Sayfaya yönlendiriliyorsunuz...",
          variant: "success",
        });
        dispatch("GET_CATEGORIES"); // kategori listesi çekilir.
        dispatch("GET_FILE_SIZE_OF_COMPANY"); // kategori listesi çekilir.
        router.push({ name: "app-start" });
      } else
        commit("SET_ALERT_BOX", {
          status: true,
          message: i18n.t("wrongEmailOrPassword"),
          variant: "danger",
        });
      commit("SET_LOGIN_IS_LOADING", false);
    },
    // for the change password
    async SEND_TO_UPDATE_CHANGE_PASSWORD({ commit }, payload) {
      const response = await vm.$api.put("Users/ChangePassword", payload);
      if (response.message === "OK") {
        setTimeout(() => {
          commit("SET_LOGOUT", {
            message: "passwordChangedWithSuccessfullyPleaseLogin",
            variant: "success",
          });
        }, 2500);
        return {
          status: true,
          variant: "success",
          message: "validMessage.updatedChangePassword",
        };
      } else if (response.message === "WRONGOLDPASSWORD")
        return {
          status: true,
          variant: "danger",
          message: "validMessage.wrongOldPassword",
        };
      else
        return {
          status: true,
          variant: "danger",
          message: "validMessage.error",
        };
    },
    async GET_COMPANY_SETTINGS({ state, commit, dispatch }) {
      const settings = { ...state.settings };
      if (
        location?.origin?.includes("http://") &&
        !location?.origin?.includes("localhost") &&
        !location?.origin?.includes("192.168.1.90")
      ) {
        setTimeout(() => {
          location.reload();
        }, 10000);
        return (state.pageIsClosed = true);
      }
      if (state.settingsLoaded) return state.settings;
      const response = await GET_COMPANY_SETTINGS(state);
      console.log(response);
      if (response.message === "OK") {
        if (response.data.settings === null) return router.push("/error");
        const companySettings = response.data.settings;
        commit("SET_HEOCADEMY_TOKEN", response.tokenResponse);
        dispatch("GET_ACTIVE_PLAN");
        const info = response.data.info;
        vm.$api.BASE_URL = info.baseUrl + "/";
        //vm.$api.BASE_URL = "http://192.168.1.98:5003/";
        vm.$api.header = {
          companyToken: response.data.companyToken,
        };
        // module settings
        if (companySettings !== null) {
          settings.isCourses = companySettings.isCourse;
          settings.isBooks = companySettings.isBook;
          settings.isBlog = companySettings.isBlog;
          settings.isEvents = companySettings.isEvent;
          settings.isSeances = companySettings.isSeance;
          settings.isFile = companySettings.isFile;
        }

        // image settings
        settings.logo = BASE_URL + info.logo;
        settings.defaultImage = "/android-chrome-384x384.png";

        // company information settings
        settings.title = info.name;
        settings.id = info.id;
        document.title = info.name;
        settings.website = info.website;
        settings.creditCardConfirmDate = info.creditCardConfirmDate;
        settings.panelWebsite = info.panelWebsite;
        settings.departmentId = info.departmentId;
        settings.remoteSubscriptionId = info.remoteId;
        if (companySettings !== null) {
          settings.primaryColor = companySettings.primaryColor;
          settings.themeColor = companySettings.themeColor;
          settings.bannerColor = companySettings.bannerColor;
        }
        console.log("burda setting app");
        // settings is loaded
        commit("SETTINGS_IS_LOADED", { settings, status: true });
        dispatch("GET_CATEGORIES"); // kategori listesi çekilir.
        if (state.activeUser.token) dispatch("GET_FILE_SIZE_OF_COMPANY"); // kategori listesi çekilir.
        return settings;
      }
      return state.settings;
    },
    async GET_FILE_SIZE_OF_COMPANY({ commit }) {
      commit("SET_LOADING_OF_FILE_SIZE_OF_COMPANY", true);

      const response = await vm.$api.get("Products/FileSize");
      if (response.message === "OK") {
        commit("SET_FILE_SIZE_OF_COMPANY", response.data);
      }
      commit("SET_LOADING_OF_FILE_SIZE_OF_COMPANY", false);
    },
    async GET_ACTIVE_PLAN({ state, commit }) {
      state.activePlan.loading = true;
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + state.heocademyToken,
        },
      };

      return fetch(
        // `${BASE_URL}/Companies/PanelSettings?panelWebsite=${location.origin}`,
        `${BASE_URL}/SubscriptionManagement/GetPlanByDate?date=${new Date().toISOString()}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => commit("SET_ACTIVE_PLAN", result));
    },
  },
};
